import Modal from "react-modal";
import { useEffect, useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import useStore from "./store/store";
import { useSnackbar } from "notistack";
import { CMS_PATH } from "./App";

const PasswordLost = () => {
  const { enqueueSnackbar } = useSnackbar();
  const showPasswordLostModal = useStore(
    (state) => state.showPasswordLostModal
  );
  const setShowPasswordLostModal = useStore(
    (state) => state.setShowPasswordLostModal
  );

  const [email, setEmail] = useState<string>("");

  const [enableButton, setEnableButton] = useState<boolean>(false);

  useEffect(() => {
    setEnableButton(email.length > 0);
  }, [email]);

  function sendRestartPassword() {
    if (email.length !== 0) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch(CMS_PATH + "/auth/password/request", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          email: email,
          reset_url: "https://makeuplab.loreal.es",
        }),
      }).then(async (response: Response) => {
        switch (response.status) {
          case 204:
            enqueueSnackbar(
              "Se ha enviado un correo para reestablecer la contraseña",
              {
                variant: "success",
              }
            );
            setShowPasswordLostModal(false);
            break;
          default:
            enqueueSnackbar("No se ha podido reestablecer la contraseña", {
              variant: "error",
            });
            break;
        }
      });
    } else {
      enqueueSnackbar(
        "Porfavor, introduzca un correo válido para reestablecer su contraseña",
        {
          variant: "error",
        }
      );
    }
  }

  return (
    <Modal
      isOpen={showPasswordLostModal}
      onRequestClose={() => setShowPasswordLostModal(false)}
      contentLabel="Register Modal"
      ariaHideApp={false}
      style={{
        content: { transform: "translate(-50%, -50%)" },
      }}
      className="bg-transparent w-full h-full border-0 p-0 top-1/2 left-1/2 right-auto bottom-auto -mr-[50%] max-h-full absolute"
    >
      <div className="w-full h-full flex justify-center items-center bg-[#00000080]">
        <div
          id="divContentPassword"
          className="p-[3rem] bg-white w-full md:w-2/5 rounded-md max-h-full md:max-h-[80%] box-border overflow-y-auto relative"
        >
          <div className="absolute right-0 mr-4">
            <svg
              className="cursor-pointer fill-marron-makeup transform hover:scale-105"
              onClick={() => setShowPasswordLostModal(false)}
              width="48"
              height="48"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"></path>
            </svg>
          </div>

          <div className="flex justify-center">
            <img className="w-[7rem] mb-6" src="./images/SVG/logoMakeUp.svg" />
          </div>
          <h1 className="font-Staatliches text-center text-4xl leading-10 tracking-widest uppercase text-marron-makeup">
            ¿Contraseña perdida?
          </h1>
          <div className="label">
            <label htmlFor="email">Email</label>
            <div>
              <input
                onKeyUp={(event: any) => setEmail(event.target.value)}
                onInput={(event: any) => setEmail(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
              />
            </div>
          </div>

          <div id="divButtonRegister" className="flex justify-center mt-12">
            <button
              id="buttonRegister"
              onClick={() => sendRestartPassword()}
              disabled={!enableButton}
              className={`w-2/3 md:w-1/2 rounded-full h-16 bg-marron-makeup ${
                enableButton ? "" : "opacity-25"
              } text-white outline-none border-0 cursor-pointer text-2xl tracking-widest font-Staatliches`}
              style={{
                filter: "drop-shadow(0px 4px 8px rgba(47, 59, 87, 0.3))",
              }}
            >
              Reestablecer
            </button>
          </div>
          {/* <h1 className="font-Staatliches text-center text-[#0066ff] mt-8">
            Desarrollado por Telefónica Educación Digital
          </h1> */}
        </div>
      </div>
    </Modal>
  );
};

export default PasswordLost;
