import { SyntheticEvent, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import useStore from "./store/store";
import { CMS_PATH } from "./App";
import { useNavigate } from "react-router-dom";

const Reset = () => {
  let navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [rePassword, setRePassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [resetToken, setResetToken] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const setShowRegisterModal = useStore((state) => state.setShowRegisterModal);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const resetPasswordToken = query.get("token");

    console.log(resetPasswordToken);

    if (resetPasswordToken !== null) {
      setResetToken(resetPasswordToken);
    }
  }, []);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (password !== rePassword) {
      enqueueSnackbar("Las contraseñas no coinciden", {
        variant: "error",
      });
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      fetch(CMS_PATH + "/auth/password/reset", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          token: resetToken,
          password: password,
        }),
      }).then(async (response: Response) => {
        switch (response.status) {
          case 204:
            enqueueSnackbar("Contraseña cambiada correctamente", {
              variant: "success",
            });
            new URLSearchParams(window.location.search).delete("token");
            navigate("/");
            break;
          default:
            enqueueSnackbar("La contraseña no se ha podido cambiar", {
              variant: "error",
            });
            break;
        }
      });
    }

    return;
  }

  return (
    <div className="absolute w-full h-full bg-cover overflow-y-auto bg-no-repeat bg-[#F1F1F1] font-Campton">
      <main className="font-Staatliches">
        <div className="w-full h-full flex absolute items-center justify-end">
          <img className="w-[30%] mr-[15%]" src="./images/SVG/logoMakeUp.svg" />
        </div>
        <div
          id="divFormLogin"
          className="w-full h-full md:h-auto md:w-1/4 left-0 md:left-[12%] absolute bg-white overflow-auto"
        >
          <div
            id="mainLogin"
            className="p-[14%] flex justify-center flex-col shadow-[0px_4px_12px_rgba(0,0,0,0.25)]"
          >
            <div className="flex flex-row gap-[10%]">
              <img
                className="object-contain w-[6rem] mx-auto"
                src="./images/SVG/logoMakeUp.svg"
              />
            </div>
            <h1 className="mt-[8%] font-normal text-3xl tracking-widest leading-[40px] text-marron-makeup">
              CAMBIA TU CONTRASEÑA
            </h1>
            <p className="font-OpenSans text-marron-makeup text-base my-6">
              Sustituye la contraseña dada por una nueva para mejorar la
              seguridad de tu cuenta.
            </p>
            <form id="formLogin" onSubmit={onSubmit} className="flex flex-col">
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm leading-5 text-marron-makeup font-OpenSans"
                  htmlFor="password"
                >
                  Nueva contraseña
                </label>
                <div>
                  <input
                    className="w-full h-[2.8rem] rounded-md bg-[#e7e7e7] box-border border-2 border-[#969696] text-sm leading-5 text-[#00000080] px-2 font-OpenSans"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Escribe la contraseña"
                    tabIndex={2}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="inline cursor-pointer -ml-9"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-8">
                <label
                  className="text-sm leading-5 text-marron-makeup font-OpenSans"
                  htmlFor="password"
                >
                  Repite la nueva contraseña
                </label>
                <div>
                  <input
                    className="w-full h-[2.8rem] rounded-md bg-[#e7e7e7] box-border border-2 border-[#969696] text-sm leading-5 text-[#00000080] px-2 font-OpenSans"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Escribe la contraseña"
                    tabIndex={2}
                    required
                    onChange={(event) => setRePassword(event.target.value)}
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="inline cursor-pointer -ml-9"
                  />
                </div>
              </div>
              <button
                id="buttonEnter"
                className="mt-12 rounded-[50px] h-16 bg-rosa-loreal text-white outline-none border-none cursor-pointer text-2xl tracking-widest font-Staatliches leading-7 hover:opacity-25"
                style={{
                  filter: "drop-shadow(0px 4px 8px rgba(47, 59, 87, 0.3))",
                }}
                onClick={onSubmit}
              >
                CAMBIAR
              </button>
            </form>
          </div>

          <footer className="relative w-full bg-marron-makeup text-white md:hidden">
            <div className="text-center flex flex-col justify-center items-center gap-2">
              <a
                className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                href="https://www.loreal.com/en/spain/pages/group/privacy-policy-spain/"
                target="_blank"
                rel="noopener noreferrer"
              >
                POLÍTICAS DE PRIVACIDAD
              </a>
              <a
                className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
                //@ts-ignore
                onClick={() => Optanon.ToggleInfoDisplay()}
              >
                POLÍTICA DE COOKIES
              </a>
            </div>
          </footer>
        </div>
        <footer className="absolute bottom-0 w-full p-3 bg-marron-makeup text-white hidden md:block">
          <div className="text-center flex flex-row justify-end items-center gap-4">
            <a
              className="text-center md:text-left text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              href="https://www.loreal.com/en/spain/pages/group/privacy-policy-spain/"
              target="_blank"
              rel="noopener noreferrer"
            >
              POLÍTICAS DE PRIVACIDAD
            </a>
            <a
              className="text-center md:text-left cursor-pointer text-white decoration-none font-Staatliches font-normal text-base leading-5 flex items-end tracking-widest uppercase"
              //@ts-ignore
              onClick={() => Optanon.ToggleInfoDisplay()}
            >
              POLÍTICA DE COOKIES
            </a>
          </div>
        </footer>
      </main>
    </div>
  );
};
export default Reset;
